import { IPlan, ISite, ITaskCategory } from "@ehabitation/ts-utils/browser";
import { saveCurrentTasksState } from "Components/Menu/thunks";
import { logSimulateBase } from "helpers/analytics";
import { useCheckPlanHash } from "hooks/useCheckPlanHash";
import { useTriggerSimulation } from "hooks/useTriggerSimulation";
import React, { FC, useState, useEffect } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "store";
import { selectTasksUpdating } from "store/ui";
import { FaSpinner } from "react-icons/fa"; // Import spinner icon
import { SimulationResult } from "helpers";
import { EndDatesTable } from "../components/EndDatesTable";
import { firebaseFunction } from "helpers";
import { useGetHeatmap } from "Components/Settings/TaskCategories/CategoryHeatmaps/hooks";
import { ISiteHeatmapData } from "Components/Settings/TaskCategories/CategoryHeatmaps/SingleCategoryHeatmap";
import {
  useSiteRiskMatrix,
  useRiskMatrixTopLevelCategories,
} from "hooks";
import { RiContrastDropLine } from "react-icons/ri";


export const SimulationView: FC<{
  syncThresholds: (projectId: string, planId: string) => void;
  projectId: string;
  plan?: IPlan;
  site: ISite;
  simulationInProgress: boolean;
  simulationResult?: SimulationResult;
}> = ({
  syncThresholds,
  projectId,
  plan,
  site,
  simulationInProgress,
  simulationResult,
}) => {

  const tasksUpdating = useAppSelector(selectTasksUpdating);

  const { minorHashDrift, majorHashDrift } = useCheckPlanHash(
    site.project,
    plan?.id
  );

  const matrixDrift = minorHashDrift || majorHashDrift;

  const { triggerSimulation } = useTriggerSimulation();

  const [syncPlanToRiskMatrixRadio, setSyncPlanToRiskMatrixRadio] =
    useState("");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncPlanToRiskMatrixRadio(event.target.value);
  };

  const { riskMatrix: siteRiskMatrix, isLoading: isLoadingSiteRiskMatrix } =
    useSiteRiskMatrix(site);
  const riskMatrix = siteRiskMatrix;

  const { categories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(riskMatrix?.id);
  
  const [categoriesWithFactorsAboveThreshold, setCategoriesWithFactorsAboveThreshold] = useState(() => ({}));

  const fetchCategoryCombinedSuccessRatio = async (site_id: string, category: ITaskCategory) => {
    try {
      const generateSiteHeatmap = firebaseFunction("GenerateSiteHeatmap");    
      const response = await generateSiteHeatmap({
        site: site_id,
        riskmatrix: { [category.name]: category },
      });

      const heatmap = (response.data as ISiteHeatmapData)?.heatmap;
      if (heatmap && typeof heatmap === 'object') {
        const categoryData = heatmap[category.name as keyof typeof heatmap];
        if (categoryData && typeof categoryData === 'object' && 'combined_success_ratio' in categoryData) {
          return categoryData.combined_success_ratio || [];
        }
      }
    } catch (error) {
      console.error("Error fetching heatmap", error);
    }
    return [];
  };

  useEffect(() => {
    if (plan && categories && site && riskMatrix) {
      const activeCategories = plan?.activeCategories ?? [];
      const planCategories = (categories ?? []).filter(category =>
        activeCategories.includes(category.name)
      );
      
      const fetchCategoriesWithFactors = async () => {
        const categoriesWithFactors = [];
        for (const category of planCategories) {
          const combinedSuccessRatio = await fetchCategoryCombinedSuccessRatio(site.id, category);

          if (combinedSuccessRatio.every(item => typeof item === 'number' && item < 0.1)) {
            categoriesWithFactors.push(category.name);
          }
        }
        setCategoriesWithFactorsAboveThreshold(categoriesWithFactors);
      };
      fetchCategoriesWithFactors();
    }
  }, [site, plan, categories, riskMatrix]);


  const hasCategoriesWithFactorsAboveThreshold = (categoriesWithFactorsAboveThreshold: {}) => {
    return Object.keys(categoriesWithFactorsAboveThreshold).length > 0;
  };

  return (
    <>
      <div className="p-4 flex flex-col justify-top h-full overflow-auto">
        {!plan && (
          <div className="text-2xl text-red-500">
            Upload, or select, a plan to run a simulation.
          </div>
        )}
        {plan && (
          <>
            {(matrixDrift || tasksUpdating) && (
              <>
                <div className="text-2xl">
                  <div className="flex gap-2 items-center text-2xl">
                    <FiAlertTriangle className="text-2xl text-gray-400 shrink-0" />{" "}
                    <label className="ml-2 text-2xl">
                      {"Weather impact thresholds have been modified. This means the weather-related risks for some activities may have changed."}
                    </label>
                    <label className="ml-2 text-2xl underline">
                      <NavLink to={"../settings/thresholds"}>
                        <span>{"Click here to see project and plan thresholds."}</span>
                      </NavLink>
                    </label>
                  </div>
                </div>
                <div className="flex flex-col mt-8">
                  <div className="ml-2 flex items-center">
                    <input
                      type="radio"
                      name="syncPlanToRiskMatrixRadio"
                      id="overwriteThresholds"
                      value="true"
                      onChange={handleRadioChange}
                    />
                    <label
                      htmlFor="overwriteThresholds"
                      className="ml-2 text-2xl"
                    >
                      Apply new thresholds to plan before simulating. Note this change is irreversible.
                    </label>
                  </div>
                  <div className="ml-2 flex items-center mt-6 mb-8">
                    <input
                      type="radio"
                      name="syncPlanToRiskMatrixRadio"
                      id="ignoreThresholds"
                      value="false"
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="ignoreThresholds" className="ml-2 text-2xl">
                      Simulate using previous thresholds.
                    </label>
                  </div>
                </div>
              </>
            )}

            {(categoriesWithFactorsAboveThreshold && hasCategoriesWithFactorsAboveThreshold(categoriesWithFactorsAboveThreshold)) && (
              <>
                <div className="text-2xl">
                  <div className="flex gap-2 items-center text-2xl">
                    <FiAlertTriangle className="text-2xl text-gray-400 shrink-0" />{" "}
                    <label className="ml-2 text-2xl">
                      {
                      "The simulation may fail. The following activity categories are likely to face issues throughout the year."}&nbsp;
                      <NavLink to={"../settings/thresholds"}>
                        <u>{"Please click here to review the project thresholds prior to running the simulation."}</u>
                      </NavLink>
                    </label>
                  </div>
                </div>
                <div className="flex flex-col mt-8">
                  <div className="ml-2 flex items-center">
                    <h4>Categories with factors above threshold:</h4>
                  </div>
                  <div className="ml-2 flex items-center">
                    <ul style={{listStyleType: "disc", marginLeft: "20px"}}>
                      {(categoriesWithFactorsAboveThreshold as any[]).map((category: any, i: number) => (
                          <li key={i}>{category}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
     
            <div className="flex justify-end">
              <button
                className={`bg-[#000] text-[#FFF] rounded-full cursor-pointer text-2xl px-12 py-3 flex gap-2 items-center ${
                  tasksUpdating ||
                  (matrixDrift && syncPlanToRiskMatrixRadio === "") ||
                  hasCategoriesWithFactorsAboveThreshold(categoriesWithFactorsAboveThreshold) ||                   
                  simulationInProgress
                    ? "disabled:bg-gray-300 disabled:text-gray-400"
                    : ""
                }`}
                onClick={async () => {
                  if (syncPlanToRiskMatrixRadio === "true") {
                    await syncThresholds(projectId!, plan?.id!);
                  }
                  saveCurrentTasksState(plan?.id);
                  logSimulateBase(site.id, plan!.id);
                  await triggerSimulation(plan!.id);
                  setSyncPlanToRiskMatrixRadio("");
                }}
                disabled={
                  tasksUpdating ||
                  (matrixDrift && syncPlanToRiskMatrixRadio === "") ||
                  simulationInProgress ||
                  !plan || 
                  hasCategoriesWithFactorsAboveThreshold(categoriesWithFactorsAboveThreshold)
                }
              >
                {simulationInProgress || tasksUpdating ? (
                  <>
                    <FaSpinner className="animate-spin" /> Simulating
                  </>
                ) : (
                  "Simulate"
                )}{" "}
                {/* Show spinner when simulating or tasks are updating */}
              </button>
            </div>

            {simulationResult && (
              <>
                <hr className="mt-20 mb-20" />
                <h2 className="mb-10">Simulation results</h2>
                <div className="flex justify-start">
                  {" "}
                  {/* Ensure this is set to justify-start */}
                  <div className="w-1/5 flex items-center justify-center mr-4">
                    <NavLink
                      to={"../plan"}
                      className="hidden sm:inline-block text-black py-[0.4rem] px-8 underline hover:text-black active:relative active:top-[1px]"
                    >
                      View summary report
                    </NavLink>
                  </div>
                  <div className="w-4/5">
                    {" "}
                    {/* Use full width for the table */}
                    <EndDatesTable
                      simulationPlanResult={simulationResult.planResults}
                      enableSubColumn2={false}
                    />
                  </div>
                  
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
