import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useLayoutEffect,
  HTMLProps,
} from "react";
import { useVirtual } from "react-virtual";
import {
  Accordion,
  Filter,
  FilterButton,
  FilterIndicator,
  Input,
  LoadingType,
  LoadingWrapper,
  MonochromeButton,
  Spinner,
  getLevelBorderColorClass,
  threeDigitsMax,
} from "@ehabitation/ui";
import { PlanImportPage } from "../types";
import { ProgressBar } from "../ProgressBar";
import {
  IPlan,
  ISite,
  ITask,
  ITaskCategory,
  WeatherThresholds,
  transformTaskDoc,
} from "@ehabitation/ts-utils/browser";
import TaskDetail from "../TaskDetail/TaskDetail";
import {
  getCategoriesByName,
  getOrderedTasksSubscription,
  getOrderedTasksSubscriptionWS,
  updateSelectedTasksCategories,
  updateTaskListCategories,
} from "../helpers";
import { FaCheck, FaSave } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { CategorySelect, CategorySelectRow } from "./CategorySelectRow";
import { useIsMounted } from "hooks";
import {
  thresholdCategories,
  thresholdUnits,
} from "Pages/sites/weather/WeatherView";
import moment from "moment";
import { useSimilarTasks } from "../TaskDetail/useSimilarTasks";
import { applyRiskMatrix } from "Components/Plan/PlanControls/thunks";
import { Chip } from "Pages/super/Organisations";
import { BiX } from "react-icons/bi";
import { doc, DocumentChange, DocumentData } from "@firebase/firestore";
import { db } from "firebaseConfig";
import { updateDoc } from "firebase/firestore";

function easeInOutQuint(t: any) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

const relationshipTitles: Record<string, string> = {
  SS: "Start - Start",
  SF: "Start - Finish",
  FS: "Finish - Start",
  FF: "Finish - Finish",
};

type TaskGroupMeta = {
  leafCount: number;
  unCategorisedCount: number;
  categorisedCounts: Record<string, number>;
};

type WBSLeafRange = TaskGroupMeta & {
  start: number;
  end?: number;
  level: number;
};

const getGroupMetaText = ({ leafCount, unCategorisedCount }: TaskGroupMeta) =>
  leafCount
    ? `${leafCount - unCategorisedCount}/${leafCount} categorised`
    : "No tasks";

export const Categories: FC<{ groupMeta: TaskGroupMeta }> = ({
  groupMeta: { unCategorisedCount, categorisedCounts },
}) => (
  <div className="p-2 flex gap-2 flex-wrap items-center max-h-64 overflow-y-auto">
    {unCategorisedCount ? (
      <Chip className="max-w-[16rem] truncate" baseBGClass="bg-pink-200">
        <>
          {`${unCategorisedCount} `}
          <span className="font-normal">uncategorised</span>
        </>
      </Chip>
    ) : null}
    {Object.entries(categorisedCounts || {})
      .sort(([_A, countA], [_B, countB]) => countB - countA)
      .map(([categoryName, count]) => (
        <Chip
          key={categoryName}
          className="max-w-[16rem] truncate"
          baseBGClass="bg-gray-200"
        >
          <>
            {count ? `${count} ` : ""}
            <span className="font-normal">{categoryName}</span>
          </>
        </Chip>
      ))}
  </div>
);

const usePlanTaskData = (siteId: string, planId?: string) => {
  const isMounted = useIsMounted();
  const [initialisedTasks, setInitialisedTasks] = useState<boolean>(false);
  const [intialisedCategories, setInitiliasedCategories] =
    useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [tasks, setTasks] = useState(new Map<string, ITask>());
  const [categoriesByName, setCategoriesByName] = useState(
    new Map<string, ITaskCategory>()
  );
  const [orderedTaskIds, setOrderedTaskIds] = useState<string[]>([]);
  const [nonWBSTaskIds, setNonWBSTaskIds] = useState(new Set<string>());
  const [assignedNonWBSTaskIds, setAssignedNonWBSTaskIds] = useState(
    new Set<string>()
  );

  useEffect(() => {
    if (planId) {
      setError("");
      getCategoriesByName(planId)
        .then(
          (categoriesByNameNew) =>
            isMounted() && setCategoriesByName(categoriesByNameNew)
        )
        .catch((error) => {
          console.error(error);
          setError("Error loading categories");
        })
        .finally(() => isMounted() && setInitiliasedCategories(true));
    }
  }, [planId]);

  const handleEmptyResults =  async () => {
    setError("No tasks found in import.");
    setInitialisedTasks(true);
  }

  const handleTasksResults = async (docChanges: DocumentChange<DocumentData>[]) => {
    const categories = Array.from(categoriesByName.values());
    setTasks((tasks) => {
      const updatedNonWBSTaskIds = new Set<string>();
      const updatedAssignedNonWBSTaskIds = new Map<string, boolean>();
      const updatedTasks = new Map<string, ITask>(tasks);
      docChanges.forEach(({ doc, type }) => {
        if (type === "removed") {
          updatedTasks.delete(doc.id);
          updatedAssignedNonWBSTaskIds.delete(doc.id);
        } else {
          const task = applyRiskMatrix(
            transformTaskDoc(doc.id, doc.data()),
            categories
          );
          updatedTasks.set(doc.id, task);

          if (!task.WBS) {
            updatedNonWBSTaskIds.add(doc.id);
            if (task.taskCategory?.selectedName || task.taskType) {
              updatedAssignedNonWBSTaskIds.set(doc.id, true);
            } else {
              updatedAssignedNonWBSTaskIds.set(doc.id, false);
            }
          }
        }
      });

      updatedNonWBSTaskIds.size &&
        setNonWBSTaskIds((nonWBSTaskIds) => {
          const newNonWBSTaskIds = new Set(nonWBSTaskIds);
          updatedNonWBSTaskIds.forEach((taskId) => {
            newNonWBSTaskIds.add(taskId);
          });
          return newNonWBSTaskIds;
        });

      updatedAssignedNonWBSTaskIds.size &&
        setAssignedNonWBSTaskIds((assignedNonWBSTaskIds) => {
          const newCompletedNonWBSTaskIds = new Set(
            assignedNonWBSTaskIds
          );
          updatedAssignedNonWBSTaskIds.forEach((isCompleted, taskId) => {
            isCompleted
              ? newCompletedNonWBSTaskIds.add(taskId)
              : newCompletedNonWBSTaskIds.delete(taskId);
          });

          return newCompletedNonWBSTaskIds;
        });

      return updatedTasks;
    });
    setOrderedTaskIds((orderedTaskIds) => {
      let updatedOrderedTaskIds: string[] | undefined;
      docChanges.forEach(({ doc, type, oldIndex, newIndex }) => {
        if (type === "added") {
          updatedOrderedTaskIds = updatedOrderedTaskIds || [
            ...orderedTaskIds,
          ];
          updatedOrderedTaskIds[newIndex] = doc.id;
        } else if (oldIndex !== newIndex) {
          updatedOrderedTaskIds = updatedOrderedTaskIds || [
            ...orderedTaskIds,
          ];
          updatedOrderedTaskIds.splice(oldIndex, 1);
          if (newIndex > -1) {
            updatedOrderedTaskIds.splice(newIndex, 0, doc.id);
          }
        }
      });
      return updatedOrderedTaskIds || orderedTaskIds;
    });
    setInitialisedTasks(true);
  }

  useEffect(() => {
    if(assignedNonWBSTaskIds.size >= 6000) {
      const planDoc = doc(db, 'plans', planId ?? "")
      updateDoc(planDoc, {
        useBatch: true
      })
    }
  }, [assignedNonWBSTaskIds])

  useEffect(() => {
    if (categoriesByName.size && planId) {
      getOrderedTasksSubscription(planId, siteId, handleTasksResults, handleEmptyResults)
    }
  }, [categoriesByName, planId]);

  // WBS structure shouldn't change from initial load.
  const wbsLeafRanges = useMemo<Map<string, WBSLeafRange>>(() => {
    if (orderedTaskIds.length === 0) return new Map();
    const wbsLeafRanges = new Map<string, WBSLeafRange>();
    const activeWBS = new Set<string>();
    let currentWBSLevel = 0;
    orderedTaskIds.forEach((taskId) => {
      const task = tasks.get(taskId);
      if (!task) throw new Error("Task not found");
      const isLeafTask = !(task.WBS || task.milestone || task.levelOfEffort);
      if (
        typeof task.wbsHierarchyLevel === "number" &&
        task.wbsHierarchyLevel != currentWBSLevel
      ) {
        if (task.wbsHierarchyLevel < currentWBSLevel) {
          activeWBS.forEach((wbsId) => {
            const wbsLeafRange = wbsLeafRanges.get(wbsId);
            if (wbsLeafRange && wbsLeafRange.level >= task.wbsHierarchyLevel!) {
              wbsLeafRange.end = task.wbsHierarchyOrder!;
              activeWBS.delete(wbsId);
            }
          });
        }
        currentWBSLevel = task.wbsHierarchyLevel!;
      }
      if (isLeafTask) {
        activeWBS.forEach((wbsId) => {
          const wbsLeafRange = wbsLeafRanges.get(wbsId);
          if (wbsLeafRange) {
            wbsLeafRange.leafCount = wbsLeafRange.leafCount + 1;
            const category = task.taskCategory?.selectedName || task.taskType;
            if (category) {
              wbsLeafRange.categorisedCounts[category] =
                (wbsLeafRange.categorisedCounts[category] || 0) + 1;
            } else {
              wbsLeafRange.unCategorisedCount =
                wbsLeafRange.unCategorisedCount + 1;
            }
          }
        });
      }
      if (task.WBS) {
        wbsLeafRanges.set(task.id, {
          start: task.wbsHierarchyOrder!,
          level: task.wbsHierarchyLevel!,
          leafCount: 0,
          unCategorisedCount: 0,
          categorisedCounts: {},
        });
        activeWBS.add(task.id);
      }
    });
    const lastTask = tasks.get(orderedTaskIds[orderedTaskIds.length - 1]);
    activeWBS.forEach((wbsId) => {
      const wbsLeafRange = wbsLeafRanges.get(wbsId);
      if (wbsLeafRange)
        wbsLeafRange.end =
          typeof lastTask?.wbsHierarchyOrder === "number"
            ? lastTask?.wbsHierarchyOrder + 1
            : -1;
    });
    return wbsLeafRanges;
  }, [orderedTaskIds, tasks]);

  return {
    tasks,
    categories: categoriesByName,
    orderedTaskIds,
    assignedNonWBSTaskIds,
    nonWBSTaskIds,
    wbsLeafRanges,
    intialisedCategories,
    initialisedTasks,
    error,
  };
};

export const IndeterminateCheckbox = ({
  indeterminate,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} {...rest} />;
};

export const useScrollToFunction = (parentRef: any) => {
  const scrollingRef = React.useRef<number>();
  return React.useCallback((offset, defaultScrollTo) => {
    const duration = 1000;
    const start = parentRef.current?.scrollTop;
    const startTime = (scrollingRef.current = Date.now());

    const run = () => {
      if (scrollingRef.current !== startTime) return;
      const now = Date.now();
      const elapsed = now - startTime;
      const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
      const interpolated = start + (offset - start) * progress;

      if (elapsed < duration) {
        defaultScrollTo(interpolated);
        requestAnimationFrame(run);
      } else {
        defaultScrollTo(interpolated);
      }
    };

    requestAnimationFrame(run);
  }, []);
};

const getRowHeight = () => 40;

const tableHeaderStickyBorderFudgeClasses =
  "before:w-full before:h-[1px] before:bg-gray-200 before:content[' '] before:absolute before:top-0";

const SELECTED = "selected";

export const PlanImportCategorySelection: FC<{
  setPage?: (page: PlanImportPage) => void;
  plan?: IPlan;
  mitigationPlanId?: string;
  site: ISite;
  handleComplete: () => void;
  initialOpenTaskId?: string;
  nextText?: string;
}> = ({
  plan,
  mitigationPlanId,
  site,
  setPage,
  handleComplete,
  initialOpenTaskId,
  nextText = "Next",
}) => {
  const isMounted = useIsMounted();

  const [error, setError] = useState<string>("");
  const [updatingTasks, setUpdatingTasks] = useState<boolean>(false);
  const [highlightedTaskId, setHighlightedTaskId] = useState<string>("");
  const pendingScrollToRef = useRef<boolean>(false);
  const [openTaskId, setOpenTaskId] = useState<string | undefined>(
    initialOpenTaskId
  );
  const [selectedTasks, setSelectedTasks] = useState<Set<string>>(new Set());
  const [deselectedTasks, setDeselectedTasks] = useState<Set<string>>(
    new Set()
  );
  const [pendingBulkCategoryUpdate, setPendingBulkCategoryUpdate] =
    useState<any>(null);
  const [expandedWBS, setExpandedWBS] = useState<Set<string>>(new Set());
  const [showOnlyUncategorised, setShowOnlyUncategorised] =
    useState<boolean>(false);
  const [titleFilter, setTitleFilter] = useState<string>("");
  const filterButtonRefs = {
    title: useRef<HTMLButtonElement>(null),
    category: useRef<HTMLButtonElement>(null),
  };

  const [blinkMultiSelect, setBlinkMultiSelect] = useState<boolean>(false);

  const {
    tasks,
    categories,
    orderedTaskIds,
    assignedNonWBSTaskIds,
    nonWBSTaskIds,
    wbsLeafRanges,
    initialisedTasks,
    intialisedCategories,
    error: categoryError,
  } = usePlanTaskData(site.id, plan?.id);

  const isInitialising = !initialisedTasks || !intialisedCategories;

  const collapsedWBS = useMemo(() => {
    const collapsedWBSIds = new Set<string>(wbsLeafRanges.keys());
    for (const taskId of expandedWBS) {
      collapsedWBSIds.delete(taskId);
    }
    return collapsedWBSIds;
  }, [expandedWBS, wbsLeafRanges]);

  const selectedTasksMeta = useMemo(() => {
    const selectedTasksMeta: TaskGroupMeta = {
      leafCount: 0,
      unCategorisedCount: 0,
      categorisedCounts: {},
    };
    selectedTasks.forEach((taskId) => {
      const task = tasks.get(taskId);
      if (!task) throw new Error("Task not found");
      const isLeafTask = !(task.WBS || task.milestone || task.levelOfEffort);
      if (isLeafTask) {
        selectedTasksMeta.leafCount++;
        const category = task.taskCategory?.selectedName || task.taskType;
        if (category) {
          selectedTasksMeta.categorisedCounts[category] =
            (selectedTasksMeta.categorisedCounts[category] || 0) + 1;
        } else {
          selectedTasksMeta.unCategorisedCount++;
        }
      }
    });
    return selectedTasksMeta;
  }, [selectedTasks, tasks, categories]);

  const openTask = openTaskId ? tasks.get(openTaskId) : undefined;
  const openTaskIsTask = useMemo(
    () => !(openTask?.WBS || openTask?.milestone || openTask?.levelOfEffort),
    [openTask]
  );

  const [filteredOrderedTaskIds, filteredLeafTaskIds, emptyWBS] =
    useMemo(() => {
      const titleFilterLower = titleFilter.toLowerCase();
      const filteredLeafTaskIds = new Set<string>();
      const emptyWBS = new Set<string>();
      const activeEmptyWBS = new Set<string>();
      let currentWBSLevel = 0;
      const updated: Set<string> = new Set();
      const allWBS = new Set<string>(wbsLeafRanges.keys());
      const filteredOrderedTaskIds = orderedTaskIds.filter((taskId) => {
        const task = tasks.get(taskId);
        if (!task) throw new Error("Task not found");
        if (task.wbsHierarchyLevel != currentWBSLevel) {
          if (task.wbsHierarchyLevel! < currentWBSLevel) {
            activeEmptyWBS.forEach((wbsId) => {
              const wbsLeafRange = wbsLeafRanges.get(wbsId);
              if (
                wbsLeafRange &&
                wbsLeafRange.level >= task.wbsHierarchyLevel!
              ) {
                activeEmptyWBS.delete(wbsId);
                emptyWBS.add(wbsId);
              }
            });
          }
          currentWBSLevel = task.wbsHierarchyLevel!;
        }
        if (taskId === openTaskId) return true;
        if (task.WBS) {
          activeEmptyWBS.add(task.id);
          return true;
        }
        if (
          titleFilter &&
          !task.title.toLowerCase().includes(titleFilterLower) &&
          !(
            task.taskCode &&
            task.taskCode.toLowerCase().includes(titleFilterLower)
          ) &&
          !(
            task.taskType &&
            task.taskType.toLowerCase().includes(titleFilterLower)
          )
        ) {
          return false;
        }
        if (showOnlyUncategorised && assignedNonWBSTaskIds.has(taskId))
          return false;
        if (task.milestone || task.levelOfEffort) return false;
        activeEmptyWBS.forEach((wbsId) => {
          activeEmptyWBS.delete(wbsId);
        });
        !task.milestone &&
          !task.levelOfEffort &&
          filteredLeafTaskIds.add(taskId);
        return true;
      });
      activeEmptyWBS.forEach((wbsId) => {
        emptyWBS.add(wbsId);
      });
      allWBS.forEach((wbsId) => {
        if (emptyWBS.has(wbsId)) return;
        updated.add(wbsId);
      });
      if (showOnlyUncategorised || titleFilter) setExpandedWBS(updated);

      return [filteredOrderedTaskIds, filteredLeafTaskIds, emptyWBS];
    }, [
      tasks,
      orderedTaskIds,
      nonWBSTaskIds,
      showOnlyUncategorised,
      openTask,
      titleFilter,
    ]);

  const [visibleOrderedTaskIds, visibleLeafTaskIds] = useMemo(() => {
    if (filteredOrderedTaskIds === orderedTaskIds && collapsedWBS.size === 0)
      return [filteredOrderedTaskIds, filteredLeafTaskIds];

    const visibleLeafTaskIds = new Set(filteredLeafTaskIds);

    let activeWBSCollapseRange: WBSLeafRange | undefined;

    const visibleOrderedTaskIds = filteredOrderedTaskIds.filter((taskId) => {
      if (emptyWBS?.has(taskId)) return false;
      const wbsLeafRange = wbsLeafRanges.get(taskId);
      if (wbsLeafRange) {
        if (activeWBSCollapseRange) {
          if (wbsLeafRange?.level > activeWBSCollapseRange?.level) {
            return false;
          } else {
            activeWBSCollapseRange = undefined;
          }
        }
        if (collapsedWBS.has(taskId)) {
          if (!activeWBSCollapseRange) activeWBSCollapseRange = wbsLeafRange;
        }
      } else {
        const task = tasks.get(taskId);
        if (!task) throw new Error("Task not found");
        if (activeWBSCollapseRange) {
          if (
            typeof activeWBSCollapseRange?.end === "undefined" ||
            task?.wbsHierarchyOrder! < activeWBSCollapseRange?.end
          ) {
            visibleLeafTaskIds.delete(taskId);
            return false;
          } else {
            activeWBSCollapseRange = undefined;
          }
        }
      }
      return true;
    });
    return [visibleOrderedTaskIds, visibleLeafTaskIds];
  }, [
    filteredOrderedTaskIds,
    filteredLeafTaskIds,
    collapsedWBS,
    wbsLeafRanges,
    emptyWBS,
  ]);

  const [visibleSelectedTaskIds, allVisibleLeafTasksSelected] = useMemo(() => {
    if (!selectedTasks.size || !visibleLeafTaskIds.size)
      return [new Set(), false];
    const updatedVisibleSelectedTaskIds = new Set<string>();
    let allVisibleSelected = true;
    visibleLeafTaskIds.forEach((taskId) => {
      if (selectedTasks.has(taskId)) {
        updatedVisibleSelectedTaskIds.add(taskId);
      } else {
        allVisibleSelected = false;
      }
    });
    return [updatedVisibleSelectedTaskIds, allVisibleSelected];
  }, [visibleOrderedTaskIds, selectedTasks]);

  const tasksContainerRef = useRef<HTMLTableSectionElement>(null);
  const scrollToFunction = useScrollToFunction(tasksContainerRef);

  const {
    virtualItems: virtualRows,
    totalSize,
    scrollToIndex,
  } = useVirtual({
    size: visibleOrderedTaskIds.length,
    parentRef: tasksContainerRef,
    estimateSize: useCallback(getRowHeight, []),
    overscan: 20,
    scrollToFn: scrollToFunction,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const scrollToVisibleTask = (taskId: string) => {
    if (taskId === SELECTED) return;
    const highlightedTask = document.querySelector(
      `[data-task-id="${taskId}"]`
    );
    if (highlightedTask) {
      const highlightedTaskRect = highlightedTask.getBoundingClientRect();
      const tasksContainerRect =
        tasksContainerRef.current!.getBoundingClientRect();
      if (
        highlightedTaskRect.top >
          tasksContainerRect.top +
            (selectedTasks.size || deselectedTasks.size ? 67 : 39) &&
        highlightedTaskRect.bottom < tasksContainerRect.bottom
      ) {
        return;
      }
    }
    scrollToIndex(
      visibleOrderedTaskIds.findIndex((id) => id === taskId),
      { align: "center" }
    );
  };

  const getParents = (taskId: string, parents: Set<string>) => {
    const targetTask = tasks.get(taskId);
    if (targetTask?.internalParentId) {
      parents.add(targetTask.internalParentId);
      getParents(targetTask.internalParentId, parents);
    }
    return parents;
  };

  const highlightAndShowTaskId = (taskId: string) => {
    const parents = new Set<string>();
    getParents(taskId, parents);
    let updatedExpandedWBS;
    if (parents.size) {
      for (const parentId of parents) {
        if (!expandedWBS.has(parentId)) {
          updatedExpandedWBS = updatedExpandedWBS || new Set(expandedWBS);
          updatedExpandedWBS.add(parentId);
        }
      }
      if (updatedExpandedWBS) {
        setExpandedWBS(updatedExpandedWBS);
      }
    }
    setHighlightedTaskId(taskId);

    // wait for WBS' to expand if necessary
    updatedExpandedWBS
      ? (pendingScrollToRef.current = true)
      : scrollToVisibleTask(taskId);
  };

  useEffect(() => {
    if (isInitialising) return;

    openTaskId ? highlightAndShowTaskId(openTaskId) : setHighlightedTaskId("");

    if (pendingBulkCategoryUpdate?.taskId !== openTaskId)
      setPendingBulkCategoryUpdate(null);
  }, [openTaskId, isInitialising]);

  useLayoutEffect(() => {
    if (isInitialising) return;

    if (pendingScrollToRef.current) {
      scrollToVisibleTask(highlightedTaskId);
      pendingScrollToRef.current = false;
    }
  }, [collapsedWBS]);

  const categoryOptions = useMemo(
    () =>
      Array.from(categories.entries()).map(([categoryName, category]) => {
        return { label: category.name, value: categoryName };
      }),
    [categories]
  );

  const orderedOpenWBSTaskIds: string[] = useMemo(() => {
    if (!openTask || !openTask.WBS) return [];
    return orderedTaskIds.filter((taskId, i) => {
      if (nonWBSTaskIds.has(taskId)) {
        const leafRange = wbsLeafRanges.get(openTask.id);
        const task = tasks.get(taskId);
        const taskHeirarchyOrder = task?.wbsHierarchyOrder as number;
        return (
          leafRange &&
          taskHeirarchyOrder >= leafRange.start &&
          (leafRange.end ? taskHeirarchyOrder < leafRange.end : true)
        );
      }
    });
  }, [openTask, wbsLeafRanges, orderedTaskIds]);

  // const anyOpenWBSTasksSelected = useMemo(
  //   () =>
  //     selectedTasks.size
  //       ? orderedOpenWBSTaskIds.some((taskId) => {
  //           return selectedTasks.has(taskId);
  //         })
  //       : false,
  //   [orderedOpenWBSTaskIds, selectedTasks]
  // );

  useEffect(() => {
    if (
      openTask?.WBS &&
      !orderedOpenWBSTaskIds.length &&
      pendingBulkCategoryUpdate?.taskId === openTaskId
    ) {
      setPendingBulkCategoryUpdate(null);
    }
  }, [pendingBulkCategoryUpdate, orderedOpenWBSTaskIds]);

  const { orderedSimilarTaskIds, loading: loadingSimilarTaskIds } =
    useSimilarTasks(tasks, orderedTaskIds, nonWBSTaskIds, openTask);

  const anySimilarTasksSelected = useMemo(
    () =>
      selectedTasks.size
        ? orderedSimilarTaskIds.some((taskId) => {
            return selectedTasks.has(taskId);
          })
        : false,
    [orderedSimilarTaskIds, selectedTasks]
  );

  const filtersDisabled = !!pendingBulkCategoryUpdate;

  useEffect(() => {
    if (selectedTasks.size || deselectedTasks.size) {
      if (!openTaskId) {
        setOpenTaskId(SELECTED);
      } else {
        highlightAndShowTaskId(SELECTED);
      }
    } else {
      openTaskId === SELECTED && setOpenTaskId(undefined);
    }
  }, [selectedTasks.size, deselectedTasks.size, pendingBulkCategoryUpdate]);

  useEffect(() => {
    if (openTaskId !== SELECTED && deselectedTasks.size) {
      setDeselectedTasks(new Set());
    }
  }, [openTaskId]);

  useEffect(() => {
    if (pendingBulkCategoryUpdate && deselectedTasks.size) {
      setDeselectedTasks(new Set());
    }
  }, [pendingBulkCategoryUpdate]);

  useEffect(() => {
    if (pendingBulkCategoryUpdate) {
      setPendingBulkCategoryUpdate(null);
    }
  }, [selectedTasks.size]);

  const orderedSelectedTaskIds = useMemo(() => {
    if (selectedTasks.size || deselectedTasks.size) {
      return orderedTaskIds.filter(
        (taskId) => selectedTasks.has(taskId) || deselectedTasks.has(taskId)
      );
    }
    return [];
  }, [orderedTaskIds, selectedTasks, deselectedTasks]);

  const openTaskCategory = useMemo(() => {
    if (
      !openTask ||
      openTask.WBS ||
      openTask.milestone ||
      openTask.levelOfEffort
    )
      return;
    const openTaskCategoryName =
      openTask.taskCategory?.selectedName || openTask?.taskType;
    const category = categories.get(openTaskCategoryName);
    return category;
  }, [openTask, categories]);

  useEffect(() => {
    if (blinkMultiSelect) {
      const timeoutId = setTimeout(() => {
        if (isMounted()) {
          setBlinkMultiSelect(false);
        }
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [blinkMultiSelect]);

  return plan ? (
    <LoadingWrapper
      message="Loading tasks..."
      type={LoadingType.alt}
      loading={isInitialising}
    >
      {!categoryError ? (
        <>
          <div className="flex items-center gap-12 justify-between px-2">
            <h2 className="shrink-0 font-bold">Assign Categories To Tasks</h2>
            <ProgressBar
              total={nonWBSTaskIds.size}
              complete={assignedNonWBSTaskIds.size}
              hidden={false}
            />
          </div>
          <div className="grid grid-cols-4 z-20">
            <div className="col-span-4 xl:col-span-3 bg-gray-50 rounded-md gap-12 flex justify-between items-center mb-1">
              <div className="flex gap-4 px-4 py-2 items-center">
                <div className="text-xl">Filter by</div>
                <Filter
                  enabledText={
                    collapsedWBS.size ? String(collapsedWBS.size) : undefined
                  }
                  enabled={!!collapsedWBS.size}
                  title="WBS"
                  disabled={filtersDisabled}
                  ref={filterButtonRefs.title}
                >
                  {({ close }) => (
                    <div className="max-h-96 w-[48rem] flex flex-col gap-2">
                      <div className="flex gap-6">
                        <IndeterminateCheckbox
                          type="checkbox"
                          id="wbs-select-all"
                          checked={
                            !!collapsedWBS.size &&
                            collapsedWBS.size === wbsLeafRanges.size
                          }
                          indeterminate={
                            !!collapsedWBS.size &&
                            collapsedWBS.size !== wbsLeafRanges.size
                          }
                          disabled={filtersDisabled}
                          onChange={() => {
                            if (expandedWBS.size) {
                              setExpandedWBS(new Set());
                            } else {
                              setExpandedWBS(new Set(wbsLeafRanges.keys()));
                            }
                            close();
                          }}
                        />
                        <span className="font-bold">
                          {collapsedWBS.size} WBS Collapsed
                        </span>
                      </div>
                      <TaskDetail.TaskList
                        allTasks={tasks}
                        orderedTaskIds={orderedTaskIds.filter((id) =>
                          wbsLeafRanges.has(id)
                        )}
                        visibleTaskIds={
                          new Set(
                            filteredOrderedTaskIds.filter(
                              (id) => !emptyWBS?.has(id)
                            )
                          )
                        }
                        highlightedTaskId={highlightedTaskId}
                        highlightTask={highlightAndShowTaskId}
                        selectTask={(taskId) => {
                          const updated = new Set(expandedWBS);
                          if (expandedWBS.has(taskId)) {
                            updated.delete(taskId);
                          } else {
                            updated.add(taskId);
                          }
                          setExpandedWBS(updated);
                        }}
                        selectedTasks={collapsedWBS}
                        disabled={filtersDisabled}
                        id="wbs-filter-list"
                      />{" "}
                    </div>
                  )}
                </Filter>
                <FilterButton
                  disabled={filtersDisabled}
                  isEnabled={showOnlyUncategorised}
                  onClick={() =>
                    setShowOnlyUncategorised(!showOnlyUncategorised)
                  }
                  className="text-xl py-[0.35rem]"
                >
                  Only Uncategorised tasks
                </FilterButton>
                {visibleLeafTaskIds.size !== nonWBSTaskIds.size && (
                  <span className="text-xl italic font-bold">
                    {nonWBSTaskIds.size - visibleLeafTaskIds.size} tasks hidden
                  </span>
                )}
              </div>
              <div className="flex gap-1 items-center px-2">
                <div className="flex items-center relative">
                  <Input
                    autoComplete="off"
                    id="filter-task-name"
                    placeholder="Filter by task or category names"
                    value={titleFilter}
                    onChange={(e) => setTitleFilter(e.target.value)}
                    className="pt-0 pb-0 pl-4 pr-20 mr-2 min-w-[270px]"
                  />
                  <button
                    className="absolute right-4 rounded-lg enabled:hover:bg-gray-200 disabled:text-gray-400"
                    aria-label="Clear Filter"
                    disabled={!titleFilter}
                    onClick={() => setTitleFilter("")}
                  >
                    <FiX className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 overflow-hidden flex-grow">
            <div className="col-span-3 h-full overflow-hidden flex flex-col gap-1">
              <div
                className="overflow-y-auto overflow-x-hidden min-h-0"
                ref={tasksContainerRef}
              >
                <div
                  style={{
                    height:
                      totalSize +
                      86 +
                      (selectedTasks.size ? 27 : 0) +
                      (openTaskId ? 8 : 0),
                  }}
                >
                  <table
                    className="mb-8 w-full !border-0 relative"
                    id="import-task-list"
                  >
                    <thead
                      className={`bg-gray-50 sticky top-0 z-10 ${tableHeaderStickyBorderFudgeClasses}`}
                    >
                      <tr className="border">
                        <th className="relative w-10">
                          <label
                            className="absolute inset-0 top-2 bottom-2 grid place-content-center"
                            htmlFor="import-list-select-all"
                          >
                            <IndeterminateCheckbox
                              className="mx-auto"
                              type="checkbox"
                              id="import-list-select-all"
                              checked={
                                !!selectedTasks.size &&
                                visibleLeafTaskIds.size ===
                                  visibleSelectedTaskIds.size
                              }
                              indeterminate={
                                !!visibleSelectedTaskIds.size &&
                                visibleLeafTaskIds.size !==
                                  visibleSelectedTaskIds.size
                              }
                              disabled={
                                pendingBulkCategoryUpdate ||
                                updatingTasks ||
                                !visibleLeafTaskIds.size
                              }
                              onChange={() => {
                                const updatedSelectedTasks = new Set(
                                  selectedTasks
                                );
                                const updatedDeselectedTasks = new Set(
                                  deselectedTasks
                                );
                                if (
                                  visibleSelectedTaskIds.size <
                                  visibleLeafTaskIds.size
                                ) {
                                  visibleLeafTaskIds.forEach((taskId) => {
                                    updatedSelectedTasks.add(taskId);
                                    updatedDeselectedTasks.delete(taskId);
                                  });
                                } else {
                                  visibleLeafTaskIds.forEach((taskId) => {
                                    updatedSelectedTasks.delete(taskId);
                                    updatedDeselectedTasks.add(taskId);
                                  });
                                }
                                setDeselectedTasks(updatedDeselectedTasks);
                                setSelectedTasks(updatedSelectedTasks);
                              }}
                            />
                            <span className="sr-only">Select All</span>
                          </label>
                        </th>
                        <th
                          scope="colgroup"
                          colSpan={2}
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border"
                        >
                          Task Name
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border w-32"
                        >
                          From
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border w-32"
                        >
                          To
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-2xl font-semibold text-gray-900 sm:pl-6 border w-96 relative"
                        >
                          <div className="flex gap-2">
                            Category
                            <FilterIndicator
                              isEnabled={!!showOnlyUncategorised}
                              handleClick={() =>
                                setShowOnlyUncategorised(!showOnlyUncategorised)
                              }
                              label="Toggle Assigned filter"
                            />
                            <div
                              className="absolute right-3 top-0 aspect-square h-full flex gap-4 items-center justify-center"
                              style={{ borderRight: "none" }}
                            >
                              <FaSave className="text-gray-500 text-2xl" />
                              {updatingTasks ? (
                                <Spinner size="xSmall" />
                              ) : (
                                <FaCheck className="text-2xl text-green-400" />
                              )}
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={6} className="text-left">
                          <button
                            color="white"
                            className="ml-1 my-1 bg-white font-normal border border-gray-200 rounded-lg px-4"
                            disabled={visibleOrderedTaskIds.length === 0}
                            onClick={() => {
                              if (expandedWBS.size) {
                                setExpandedWBS(new Set());
                              } else {
                                setExpandedWBS(new Set(wbsLeafRanges.keys()));
                              }
                            }}
                          >
                            {expandedWBS.size ? "Collapse all" : "Expand all"}
                          </button>
                        </th>
                      </tr>
                      {selectedTasks.size || deselectedTasks.size ? (
                        <>
                          <tr key={selectedTasks.size}>
                            <td colSpan={6} className="relative">
                              <div className="absolute -top-[1px] -left-[1px] -right-[1px] h-16 border-2 z-10 pointer-events-none animate-[ping_1s_cubic-bezier(0,0,0.2,1)_1] border-yellow-300" />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6} className="relative">
                              <div
                                className={`absolute -top-[1px] -left-[1px] -right-[1px] h-16 border-2 z-10 pointer-events-none animate-[ping_1s_cubic-bezier(0,0,0.2,1)_1]  ${
                                  openTaskId === SELECTED
                                    ? "border-black"
                                    : "border-gray-200"
                                }`}
                              />
                            </td>
                          </tr>
                          <tr
                            onClick={
                              updatingTasks ||
                              (pendingBulkCategoryUpdate &&
                                pendingBulkCategoryUpdate.taskId !== SELECTED)
                                ? undefined
                                : () => setOpenTaskId(SELECTED)
                            }
                            className={`relative h-16 font-extrabold ${
                              updatingTasks ||
                              (pendingBulkCategoryUpdate &&
                                pendingBulkCategoryUpdate.taskId !== SELECTED)
                                ? "opacity-50"
                                : "hover:bg-yellow-100 cursor-pointer"
                            } ${
                              highlightedTaskId === SELECTED
                                ? " bg-yellow-200"
                                : " bg-yellow-100"
                            } ${blinkMultiSelect ? "animate-pulse" : ""}`}
                          >
                            <td className="px-4 pl-1" colSpan={5}>
                              <div className="flex items-center gap-1">
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setSelectedTasks(new Set());
                                    setDeselectedTasks(new Set());
                                  }}
                                  className={`min-w-0 flex-shrink-0 pl-[0.1rem] pr-[0.2rem] py-[0.5rem] group rounded-lg`}
                                  title="Clear Selected"
                                >
                                  <BiX className="w-8 h-8 rounded-lg text-black bg-gray-200 group-hover:font-bold group-hover:bg-gray-100" />
                                </button>
                                <span className="text-3xl">
                                  {selectedTasks.size} Selected tasks
                                </span>
                              </div>
                            </td>
                            <td
                              className="-m-px relative"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="absolute inset-0 ">
                                <CategorySelect
                                  blinkMultiSelect={blinkMultiSelect}
                                  value={
                                    pendingBulkCategoryUpdate?.taskId ===
                                    SELECTED
                                      ? pendingBulkCategoryUpdate.category?.name
                                      : ""
                                  }
                                  showClear={true}
                                  disabled={
                                    updatingTasks ||
                                    !!pendingBulkCategoryUpdate ||
                                    !selectedTasks.size
                                  }
                                  placeholder={`${
                                    selectedTasksMeta.leafCount -
                                    selectedTasksMeta.unCategorisedCount
                                  }/${selectedTasksMeta.leafCount} categorised`}
                                  updateTaskCategory={(categoryName) => {
                                    const category = categoryName
                                      ? categories.get(categoryName)
                                      : { name: "" };
                                    setOpenTaskId(SELECTED);
                                    setPendingBulkCategoryUpdate({
                                      taskId: SELECTED,
                                      category,
                                    });
                                  }}
                                  categoryOptions={categoryOptions}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      ) : null}
                    </thead>
                    <tbody
                      className={`${
                        pendingBulkCategoryUpdate ? "opacity-60 " : ""
                      } ${updatingTasks ? "animate-pulse" : ""}
                    `}
                    >
                      {paddingTop > 0 && (
                        <tr>
                          <td style={{ height: `${paddingTop}px` }} />
                        </tr>
                      )}
                      {virtualRows.map((virtualRow) => {
                        const taskId = visibleOrderedTaskIds[virtualRow.index];
                        const task = tasks.get(taskId);
                        const toggleCollapsed = task?.WBS
                          ? () => {
                              const updated = new Set(expandedWBS);
                              if (expandedWBS.has(task.id)) {
                                updated.delete(task.id);
                              } else {
                                updated.add(task.id);
                              }
                              setExpandedWBS(updated);
                            }
                          : undefined;
                        const wbsLeafRange =
                          task?.WBS && wbsLeafRanges.get(task.id);
                        const placeholderOverride = wbsLeafRange
                          ? wbsLeafRange.leafCount
                            ? `${
                                wbsLeafRange.leafCount -
                                wbsLeafRange.unCategorisedCount
                              }/${wbsLeafRange.leafCount} categorised`
                            : "No tasks"
                          : undefined;
                        return task ? (
                          <CategorySelectRow
                            key={task.id}
                            isCollapsed={
                              task.WBS ? !expandedWBS.has(task.id) : undefined
                            }
                            toggleCollapsed={toggleCollapsed}
                            highlighted={highlightedTaskId === task.id}
                            open={openTask?.id === task.id}
                            handleClick={() => {
                              openTaskId === task.id
                                ? toggleCollapsed?.()
                                : setOpenTaskId(task.id);
                            }}
                            handleSelect={
                              task.WBS || task.milestone || task.levelOfEffort
                                ? undefined
                                : () => {
                                    const updatedSet = new Set(selectedTasks);
                                    if (updatedSet.has(task.id)) {
                                      updatedSet.delete(task.id);
                                      if (openTaskId === SELECTED) {
                                        const updatedDeselectedSet = new Set(
                                          deselectedTasks
                                        );
                                        updatedDeselectedSet.has(task.id)
                                          ? updatedDeselectedSet.delete(task.id)
                                          : updatedDeselectedSet.add(task.id);
                                        setDeselectedTasks(
                                          updatedDeselectedSet
                                        );
                                      }
                                    } else {
                                      updatedSet.add(task.id);
                                    }
                                    setSelectedTasks(updatedSet);
                                  }
                            }
                            selected={selectedTasks.has(task.id)}
                            task={task}
                            parent={task.WBS}
                            disabled={
                              updatingTasks || pendingBulkCategoryUpdate
                            }
                            categoryOptions={categoryOptions}
                            pendingCategory={
                              pendingBulkCategoryUpdate?.taskId === task.id
                                ? pendingBulkCategoryUpdate?.category
                                : undefined
                            }
                            selectPlaceholderOverride={placeholderOverride}
                            updateTaskCategory={
                              selectedTasks.size ||
                              (wbsLeafRange && !wbsLeafRange.leafCount)
                                ? undefined
                                : (categoryName: string) => {
                                    const category = categoryName
                                      ? categories.get(categoryName)
                                      : { name: "" };
                                    if (!category)
                                      throw new Error("Category not found");
                                    if (task.WBS) {
                                      setOpenTaskId(task.id);
                                      setPendingBulkCategoryUpdate({
                                        taskId: task.id,
                                        category,
                                      });
                                    } else {
                                      updateTaskListCategories(
                                        tasks,
                                        visibleOrderedTaskIds,
                                        virtualRow.index,
                                        true,
                                        category,
                                        plan!.id,
                                        mitigationPlanId,
                                        setUpdatingTasks,
                                        setError
                                      ).then(() => {
                                        isMounted() && setOpenTaskId(task.id);
                                      });
                                    }
                                  }
                            }
                            setBlinkMultiSelect={setBlinkMultiSelect}
                          />
                        ) : null;
                      })}
                      {paddingBottom > 0 && (
                        <tr>
                          <td style={{ height: `${paddingBottom}px` }} />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="py-2 col-span-1 overflow-hidden">
              <TaskDetail>
                {openTaskId || pendingBulkCategoryUpdate ? (
                  <>
                    {openTask ? (
                      <div>
                        <TaskDetail.TaskTitle
                          title={openTask.title}
                          task={openTask}
                          taskId={openTask.id}
                          isHighlighted={highlightedTaskId === openTask.id}
                          highlightTask={highlightAndShowTaskId}
                          visible={filteredOrderedTaskIds.includes(openTask.id)}
                          footer={
                            <div className="rounded-t-lg border border-b-0 bg-gray-50">
                              <Accordion
                                headerClass="flex items-center gap-1 w-full hover:bg-gray-100"
                                title={({ open }) => (
                                  <div className="flex-grow flex justify-between items-center pr-2 gap-2">
                                    <p>
                                      {openTask.WBS ? (
                                        <span className="font-bold">WBS</span>
                                      ) : openTask.milestone ? (
                                        <span className="text-blue-500 tracking-wider">
                                          Milestone
                                        </span>
                                      ) : openTask.levelOfEffort ? (
                                        <span className="text-blue-500 tracking-wider">
                                          Level of Effort
                                        </span>
                                      ) : (
                                        "Task"
                                      )}
                                    </p>
                                    {open ? null : (
                                      <p className="truncate text-gray-500">
                                        {openTask.milestone
                                          ? openTask.start.toLocaleDateString()
                                          : `${
                                              moment(openTask.end).diff(
                                                openTask.start,
                                                "days"
                                              ) + 1
                                            } day${
                                              moment(openTask.end).diff(
                                                openTask.start,
                                                "days"
                                              ) +
                                                1 ===
                                              1
                                                ? ""
                                                : "s"
                                            }`}
                                      </p>
                                    )}
                                  </div>
                                )}
                              >
                                <div className="flex flex-col gap-6 pb-6 px-4">
                                  <div className="flex flex-col">
                                    {openTask.milestone ? null : (
                                      <div className="self-stretch flex justify-between">
                                        <p>
                                          {openTask.start.toLocaleDateString()}
                                        </p>{" "}
                                        <p>
                                          {openTask.end.toLocaleDateString()}
                                        </p>
                                      </div>
                                    )}
                                    <div className="self-stretch border border-gray-400 border-x-2 border-x-gray-600 bg-blue-300 mx-12 grid place-items-center">
                                      {openTask.milestone
                                        ? openTask.start.toLocaleDateString()
                                        : `${
                                            moment(openTask.end).diff(
                                              openTask.start,
                                              "days"
                                            ) + 1
                                          } day${
                                            moment(openTask.end).diff(
                                              openTask.start,
                                              "days"
                                            ) === 0
                                              ? ""
                                              : "s"
                                          }`}
                                    </div>
                                  </div>
                                  <div className="border-b border-gray-400 flex items-center justify-between gap-4 leading-6">
                                    <p>Object Id</p>
                                    <p>{openTask.objectId}</p>
                                  </div>
                                  {openTask.categoryCode ? (
                                    <div className="border-b border-gray-400 flex items-center justify-between gap-4 leading-6">
                                      <p>Category Code</p>
                                      <p>{openTask.categoryCode}</p>
                                    </div>
                                  ) : null}
                                  {openTask.guid ? (
                                    <div className="border-b border-gray-400 flex items-center justify-between gap-4 leading-6">
                                      <p>GUID</p>
                                      <p className="text-xl">{openTask.guid}</p>
                                    </div>
                                  ) : null}
                                </div>
                              </Accordion>
                            </div>
                          }
                        />
                      </div>
                    ) : null}
                    {openTaskId === SELECTED ? (
                      <TaskDetail.TaskTitle
                        visible={true}
                        title={`${selectedTasks.size} Selected Tasks`}
                        task={openTask}
                        taskId={openTaskId}
                        isHighlighted={highlightedTaskId === openTaskId}
                        highlightTask={highlightAndShowTaskId}
                      />
                    ) : null}
                    <div
                      className={`flex-grow flex-shrink overflow-y-auto overflow-x-hidden p-2 border-x border-x-gray-200 flex flex-col gap-2 ${
                        openTask?.milestone ? "border-b rounded-b-lg" : ""
                      }`}
                    >
                      {pendingBulkCategoryUpdate &&
                      pendingBulkCategoryUpdate.taskId === openTaskId ? (
                        <div className="min-h-fit p-2">
                          <p>
                            {pendingBulkCategoryUpdate.category?.name ? (
                              <>
                                Apply category{" "}
                                <span className="italic font-bold">
                                  {pendingBulkCategoryUpdate.category.name}{" "}
                                </span>
                                to{" "}
                              </>
                            ) : (
                              "Clear the category of"
                            )}{" "}
                            {pendingBulkCategoryUpdate.taskId === SELECTED ? (
                              `${selectedTasks.size} selected task${
                                selectedTasks.size === 1 ? "" : "s"
                              }?`
                            ) : (
                              <>
                                <span className="font-bold">
                                  all {orderedOpenWBSTaskIds.length}
                                </span>{" "}
                                sub tasks, or only unassigned of this WBS?
                              </>
                            )}
                          </p>
                          <div className="flex flex-row lg:flex-col gap-2 my-6 justify-between">
                            {!!pendingBulkCategoryUpdate.category?.name && (
                              <MonochromeButton
                                level="primary"
                                onClick={() =>
                                  pendingBulkCategoryUpdate.taskId === SELECTED
                                    ? updateSelectedTasksCategories(
                                        tasks,
                                        selectedTasks,
                                        false,
                                        pendingBulkCategoryUpdate.category,
                                        plan!.id,
                                        mitigationPlanId,
                                        setUpdatingTasks,
                                        setError
                                      ).finally(() => {
                                        isMounted() &&
                                          setPendingBulkCategoryUpdate(null);
                                        setSelectedTasks(new Set());
                                        setDeselectedTasks(new Set());
                                      })
                                    : updateTaskListCategories(
                                        tasks,
                                        orderedTaskIds,
                                        orderedTaskIds.findIndex(
                                          (id) =>
                                            id ===
                                            pendingBulkCategoryUpdate.taskId
                                        ),
                                        false,
                                        pendingBulkCategoryUpdate.category,
                                        plan!.id,
                                        mitigationPlanId,
                                        setUpdatingTasks,
                                        setError
                                      ).finally(() => {
                                        isMounted() &&
                                          setPendingBulkCategoryUpdate(null);
                                      })
                                }
                                disabled={updatingTasks}
                              >
                                Apply To Unassigned
                              </MonochromeButton>
                            )}
                            <MonochromeButton
                              level={
                                !pendingBulkCategoryUpdate.category?.name
                                  ? "primary"
                                  : "secondary"
                              }
                              className="mt-3"
                              onClick={() =>
                                pendingBulkCategoryUpdate.taskId === SELECTED
                                  ? updateSelectedTasksCategories(
                                      tasks,
                                      selectedTasks,
                                      true,
                                      pendingBulkCategoryUpdate.category,
                                      plan!.id,
                                      mitigationPlanId,
                                      setUpdatingTasks,
                                      setError
                                    ).finally(() => {
                                      isMounted() &&
                                        setPendingBulkCategoryUpdate(null);
                                      setSelectedTasks(new Set());
                                      setDeselectedTasks(new Set());
                                    })
                                  : updateTaskListCategories(
                                      tasks,
                                      orderedTaskIds,
                                      orderedTaskIds.findIndex(
                                        (id) =>
                                          id ===
                                          pendingBulkCategoryUpdate.taskId
                                      ),
                                      true,
                                      pendingBulkCategoryUpdate.category,
                                      plan!.id,
                                      mitigationPlanId,
                                      setUpdatingTasks,
                                      setError
                                    ).finally(() => {
                                      isMounted() &&
                                        setPendingBulkCategoryUpdate(null);
                                    })
                              }
                              disabled={updatingTasks}
                            >
                              {!pendingBulkCategoryUpdate.category?.name
                                ? "Clear"
                                : "Apply To All"}
                            </MonochromeButton>
                            <MonochromeButton
                              level="tertiary"
                              className="mt-3"
                              onClick={() => setPendingBulkCategoryUpdate(null)}
                              disabled={updatingTasks}
                            >
                              Cancel
                            </MonochromeButton>
                          </div>
                          {pendingBulkCategoryUpdate.category?.thresholds ? (
                            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2 auto-rows-fr grid-flow-row-dense p-2">
                              {thresholdCategories
                                .filter(
                                  ({ key }) =>
                                    typeof pendingBulkCategoryUpdate.category
                                      .thresholds[key] === "number"
                                )
                                .map(({ title, key }) => {
                                  const { [key]: thresholdValue } =
                                    pendingBulkCategoryUpdate.category
                                      .thresholds as WeatherThresholds;
                                  return (
                                    <div
                                      key={key}
                                      className="min-w-[7rem] text-base p-2 flex flex-row items-center gap-1 justify-between border-b border-gray-300"
                                    >
                                      <div className="font-[500]">{title}</div>
                                      <div className=" whitespace-nowrap pr-10 relative">
                                        {typeof thresholdValue === "number"
                                          ? threeDigitsMax(thresholdValue, true)
                                          : "-"}{" "}
                                        <span
                                          className={`text-xl absolute left-[calc(100%-2.1rem)] ${
                                            typeof thresholdValue === "number"
                                              ? "-top-1"
                                              : ""
                                          }`}
                                        >
                                          {thresholdUnits[key]}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <>
                          {(openTaskId === SELECTED ||
                            (openTask &&
                              !openTask.milestone &&
                              !openTask.levelOfEffort)) &&
                          !(
                            (openTaskIsTask && !openTaskCategory) ||
                            openTaskCategory?.name === "No Weather Impact"
                          ) ? (
                            <div className="rounded-lg border border-gray-200">
                              <Accordion
                                defaultOpen
                                headerClass={`flex items-center gap-1 w-full ${
                                  openTaskId === SELECTED
                                    ? ""
                                    : "hover:bg-gray-100"
                                }`}
                                title={({ open }) => (
                                  <div className="flex-grow flex justify-between items-center pr-2 gap-2 overflow-hidden">
                                    <h4 className="text-2xl leading-10">
                                      {(openTask &&
                                        wbsLeafRanges.get(openTask.id)) ||
                                      openTaskId === SELECTED
                                        ? "Categories"
                                        : "Thresholds"}
                                    </h4>
                                    {open ? null : (
                                      <p className="truncate text-gray-500">
                                        {openTaskId === SELECTED
                                          ? getGroupMetaText(selectedTasksMeta)
                                          : openTask?.id &&
                                            wbsLeafRanges.get(openTask?.id)
                                          ? getGroupMetaText(
                                              wbsLeafRanges.get(openTask?.id)!
                                            )
                                          : openTask?.taskCategory
                                              ?.selectedName ||
                                            openTask?.taskType}
                                      </p>
                                    )}
                                  </div>
                                )}
                              >
                                <>
                                  {openTask &&
                                    wbsLeafRanges.get(openTask.id) && (
                                      <Categories
                                        groupMeta={
                                          wbsLeafRanges.get(openTask.id)!
                                        }
                                      />
                                    )}
                                  {openTaskId === SELECTED && (
                                    <Categories groupMeta={selectedTasksMeta} />
                                  )}
                                  {openTaskCategory &&
                                  openTaskCategory.name !==
                                    "No Weather Impact" ? (
                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2 auto-rows-fr grid-flow-row-dense p-2">
                                      {thresholdCategories.map(
                                        ({ title, key }) => {
                                          const thresholds = openTask as ITask;
                                          return typeof thresholds?.[key] ===
                                            "number" ? (
                                            <div
                                              key={key}
                                              className="min-w-[7rem] text-base p-2 flex flex-row items-center gap-1 justify-between border-b border-gray-300"
                                            >
                                              <div className="font-[500]">
                                                {title}
                                              </div>
                                              <div className=" whitespace-nowrap pr-10 relative">
                                                {typeof thresholds?.[key] ===
                                                "number"
                                                  ? threeDigitsMax(
                                                      thresholds[key]!,
                                                      true
                                                    )
                                                  : "-"}{" "}
                                                <span className="text-xl absolute left-[calc(100%-2.1rem)] -top-1">
                                                  {thresholdUnits[key]}
                                                </span>
                                              </div>
                                            </div>
                                          ) : null;
                                        }
                                      )}
                                    </div>
                                  ) : null}
                                </>
                              </Accordion>
                            </div>
                          ) : null}
                          {openTask ? (
                            <div className="rounded-lg border border-gray-200">
                              <Accordion
                                defaultOpen={false}
                                panelClass="px-4 py-2"
                                headerClass="flex items-center gap-1 w-full hover:bg-gray-100"
                                title={({ open }) => (
                                  <div className="flex-grow flex justify-between items-center pr-2 gap-2 overflow-hidden">
                                    <h4 className="text-2xl leading-10">
                                      Relationships
                                    </h4>
                                    {open ? null : (
                                      <p className="truncate text-gray-500">
                                        {openTask?.relationship
                                          ? Object.values(
                                              openTask.relationship
                                            ).flat().length
                                          : 0}
                                      </p>
                                    )}
                                  </div>
                                )}
                              >
                                {(
                                  openTask?.relationship
                                    ? Object.values(
                                        openTask.relationship
                                      ).flat().length
                                    : 0
                                ) ? (
                                  <div className="flex flex-col gap-2">
                                    {Object.keys(relationshipTitles).map(
                                      (key) =>
                                        openTask?.relationship?.[key]
                                          ?.length ? (
                                          <div key={key}>
                                            <h4 className="text-2xl leading-10 font-[500]">
                                              {relationshipTitles[key] || key}
                                            </h4>
                                            <div className="flex flex-col max-h-96">
                                              <TaskDetail.TaskList
                                                id={
                                                  "task-relationships-list-" +
                                                  key
                                                }
                                                allTasks={tasks}
                                                orderedTaskIds={openTask?.relationship?.[
                                                  key
                                                ]?.sort((aId, bId) => {
                                                  const a = tasks.get(aId);
                                                  const b = tasks.get(bId);
                                                  if (!a || !b) return 0;
                                                  return (
                                                    (a?.wbsHierarchyOrder ||
                                                      0) -
                                                    (b?.wbsHierarchyOrder || 0)
                                                  );
                                                })}
                                                visibleTaskIds={
                                                  new Set(
                                                    filteredOrderedTaskIds
                                                  )
                                                }
                                                highlightedTaskId={
                                                  highlightedTaskId
                                                }
                                                highlightTask={
                                                  highlightAndShowTaskId
                                                }
                                              />
                                            </div>
                                          </div>
                                        ) : null
                                    )}
                                  </div>
                                ) : (
                                  <p>None</p>
                                )}
                              </Accordion>
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                    <div
                      className={`flex-shrink-0 min-h-0 overflow-hidden rounded-b-lg bg-gray-50 ${
                        openTask?.milestone || openTask?.levelOfEffort
                          ? ""
                          : "border border-t-2 border-x-gray-200 border-b-gray-200 " +
                            (openTask
                              ? getLevelBorderColorClass(openTask)
                              : "border-gray-300")
                      }`}
                    >
                      {(!(openTaskId === SELECTED) &&
                        openTask?.WBS &&
                        orderedOpenWBSTaskIds.length) ||
                      openTask?.milestone ||
                      openTask?.levelOfEffort ? null : (
                        <Accordion
                          headerClass="w-full flex items-center gap-1 hover:bg-gray-100"
                          title={() => (
                            <h4 className="text-2xl leading-10">
                              {openTaskId === SELECTED || openTask?.WBS
                                ? "Tasks"
                                : "Similar Tasks"}
                            </h4>
                          )}
                          defaultOpen
                        >
                          <div className="py-2 px-4">
                            {openTaskId === SELECTED ? (
                              <>
                                {orderedSelectedTaskIds.length > 0 && (
                                  <div
                                    role="button"
                                    className="mb-3 inline-block underline text-blue-500"
                                    onClick={
                                      updatingTasks
                                        ? undefined
                                        : () => {
                                            const updatedSelectedTasks =
                                              new Set(selectedTasks);
                                            const updatedDeselectedTasks =
                                              new Set(deselectedTasks);
                                            if (selectedTasks.size) {
                                              selectedTasks.forEach(
                                                (taskId) => {
                                                  updatedDeselectedTasks.add(
                                                    taskId
                                                  );
                                                  updatedSelectedTasks.delete(
                                                    taskId
                                                  );
                                                }
                                              );
                                            } else {
                                              deselectedTasks.forEach(
                                                (taskId) => {
                                                  updatedDeselectedTasks.delete(
                                                    taskId
                                                  );
                                                  updatedSelectedTasks.add(
                                                    taskId
                                                  );
                                                }
                                              );
                                            }
                                            setDeselectedTasks(
                                              updatedDeselectedTasks
                                            );
                                            setSelectedTasks(
                                              updatedSelectedTasks
                                            );
                                          }
                                    }
                                  >
                                    {selectedTasks.size ? "de" : ""}select all
                                  </div>
                                )}
                                <div className="flex flex-col max-h-96">
                                  <TaskDetail.TaskList
                                    data-testid="selected-tasks-list"
                                    id="selected-tasks-list"
                                    key={openTaskId}
                                    disabled={updatingTasks}
                                    allTasks={tasks}
                                    orderedTaskIds={orderedSelectedTaskIds}
                                    visibleTaskIds={filteredLeafTaskIds}
                                    highlightedTaskId={highlightedTaskId}
                                    highlightTask={highlightAndShowTaskId}
                                    selectedTasks={selectedTasks}
                                    selectTask={(taskId) => {
                                      const updatedDeselectedTasks = new Set(
                                        deselectedTasks
                                      );
                                      const updatedSelectedTasks = new Set(
                                        selectedTasks
                                      );
                                      if (selectedTasks.has(taskId)) {
                                        updatedDeselectedTasks.add(taskId);
                                        updatedSelectedTasks.delete(taskId);
                                      } else {
                                        updatedDeselectedTasks.delete(taskId);
                                        updatedSelectedTasks.add(taskId);
                                      }
                                      setDeselectedTasks(
                                        updatedDeselectedTasks
                                      );
                                      setSelectedTasks(updatedSelectedTasks);
                                    }}
                                  />
                                </div>
                              </>
                            ) : openTask?.WBS ? (
                              orderedOpenWBSTaskIds.length ? (
                                <>
                                  {/* <div
                                    role="button"
                                    className="mb-3 inline-block underline text-blue-500"
                                    onClick={
                                      updatingTasks
                                        ? undefined
                                        : () => {
                                            if (!anyOpenWBSTasksSelected)
                                              return setSelectedTasks(
                                                new Set([
                                                  ...selectedTasks,
                                                  ...orderedOpenWBSTaskIds,
                                                ])
                                              );
                                            const updatedSelectedTasks =
                                              new Set(selectedTasks);
                                            orderedOpenWBSTaskIds.forEach(
                                              (taskId) => {
                                                updatedSelectedTasks.delete(
                                                  taskId
                                                );
                                              }
                                            );
                                            setSelectedTasks(
                                              updatedSelectedTasks
                                            );
                                          }
                                    }
                                  >
                                    {anyOpenWBSTasksSelected ? "de" : ""}select all
                                  </div>
                                  <div className="flex flex-col max-h-96">
                                    <TaskDetail.TaskList
                                      data-testid="wbs-detail-list"
                                      id="wbs-detail-list"
                                      key={openTaskId}
                                      allTasks={tasks}
                                      disabled={updatingTasks}
                                      orderedTaskIds={orderedOpenWBSTaskIds}
                                      visibleTaskIds={filteredLeafTaskIds}
                                      highlightedTaskId={highlightedTaskId}
                                      highlightTask={highlightAndShowTaskId}
                                      selectedTasks={selectedTasks}
                                      selectTask={(taskId) => {
                                        const updated = new Set(selectedTasks);
                                        if (selectedTasks.has(taskId)) {
                                          updated.delete(taskId);
                                        } else {
                                          updated.add(taskId);
                                        }
                                        setSelectedTasks(updated);
                                      }}
                                    />
                                  </div> */}
                                </>
                              ) : (
                                <p className="text-gray-500">None</p>
                              )
                            ) : orderedSimilarTaskIds.length ? (
                              <LoadingWrapper
                                loading={loadingSimilarTaskIds}
                                message={"Finding Similar Tasks..."}
                                type={LoadingType.subscriptions}
                              >
                                <div
                                  role="button"
                                  className="mb-3 inline-block underline text-blue-500"
                                  onClick={
                                    updatingTasks
                                      ? undefined
                                      : () => {
                                          if (!anySimilarTasksSelected)
                                            return setSelectedTasks(
                                              new Set([
                                                ...selectedTasks,
                                                ...orderedSimilarTaskIds,
                                              ])
                                            );
                                          const updatedSelectedTasks = new Set(
                                            selectedTasks
                                          );
                                          orderedSimilarTaskIds.forEach(
                                            (taskId) => {
                                              updatedSelectedTasks.delete(
                                                taskId
                                              );
                                            }
                                          );
                                          setSelectedTasks(
                                            updatedSelectedTasks
                                          );
                                        }
                                  }
                                >
                                  {anySimilarTasksSelected ? "de" : ""}select
                                  all
                                </div>
                                <div className="flex flex-col max-h-96">
                                  <TaskDetail.TaskList
                                    data-testid="similar-tasks-list"
                                    id="similar-tasks-list"
                                    key={openTaskId}
                                    allTasks={tasks}
                                    disabled={updatingTasks}
                                    orderedTaskIds={orderedSimilarTaskIds}
                                    visibleTaskIds={filteredLeafTaskIds}
                                    highlightedTaskId={highlightedTaskId}
                                    highlightTask={highlightAndShowTaskId}
                                    selectedTasks={selectedTasks}
                                    selectTask={(taskId) => {
                                      const updated = new Set(selectedTasks);
                                      if (selectedTasks.has(taskId)) {
                                        updated.delete(taskId);
                                      } else {
                                        updated.add(taskId);
                                      }
                                      setSelectedTasks(updated);
                                    }}
                                  />
                                </div>
                              </LoadingWrapper>
                            ) : (
                              <p className="text-gray-500">None</p>
                            )}
                          </div>
                        </Accordion>
                      )}
                    </div>
                  </>
                ) : (
                  <p>Click a task to see more details.</p>
                )}
              </TaskDetail>
            </div>
            <div className="absolute bottom-6 right-8 flex gap-6 justify-between">
              {setPage ? (
                <MonochromeButton
                  className="self-end"
                  level="secondary"
                  onClick={() => setPage(PlanImportPage.Draft)}
                  width="md"
                  name="discard"
                  data-testid="draft-slide-discard-button"
                >
                  Back
                </MonochromeButton>
              ) : (
                <div />
              )}
              {
                <MonochromeButton
                  className="self-end"
                  level="primary"
                  onClick={() => handleComplete()}
                  disabled={!plan!.id || updatingTasks}
                  width="md"
                  name="continue"
                  data-testid="draft-slide-continue-button"
                >
                  {nextText}
                </MonochromeButton>
              }
            </div>
          </div>
        </>
      ) : (
        <div className="w-full h-full grid place-content-center">
          <div className="flex flex-col items-center gap-8">
            <strong className="text-red-600">{categoryError}</strong>
            {setPage ? (
              <MonochromeButton
                className="self-end"
                level="secondary"
                onClick={() => setPage(PlanImportPage.Draft)}
                width="md"
                name="discard"
                data-testid="draft-slide-discard-button"
              >
                Back
              </MonochromeButton>
            ) : null}
          </div>
        </div>
      )}
    </LoadingWrapper>
  ) : null;
};
