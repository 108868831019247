import { Modal, Sidebar } from "@ehabitation/ui";
import { FC, useEffect, useMemo, useState } from "react";

import {
  IPlan,
  IProject,
  IRiskMatrix,
  ISite,
  ITaskCategory,
} from "@ehabitation/ts-utils/browser";
import SiteWeatherDownloadSlide from "Components/HistoricalSiteStatsModal/SiteWeatherDownloadSlide";
import { setAsMainPlan } from "Components/Plan/PlanControls/thunks";
import Categorise from "Components/PlanImport/Categorise";
import PlanImport from "Components/PlanImport/PlanImport";
import CompensationEvents from "Components/Settings/CompensationEvents/CompensationEvents";
import {
  useProcessedTasks,
  useSimulation,
  useSimulationResult,
  useTaskDocs,
} from "Pages/sites/hooks";
import { MitigationsView } from "Pages/sites/mitigations/MitigationsView";
import { PlanView } from "Pages/sites/plan/PlanView";
import { setActiveSiteId } from "Pages/sites/thunks";
import { VarianceView } from "Pages/sites/variance/VarianceView";
import JourneyWrapper from "Routers/JourneyWrapper";
import { SidebarContent } from "Routers/SidebarContent";
import { SidebarContentJourneys } from "Routers/SidebarContentJourneys";
import { syncThresholds } from "helpers/firebase/riskMatrix";
import {
  useCheckPlanHash,
  useFeatureFlag,
  useOpenSiteEvent,
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useTaskTypes,
} from "hooks";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { selectFileIsLoading, selectTasksUpdating } from "store/ui";
import { ComparisonPlanLoader } from "./ComparisonPlanLoader";
import { MainWrapper } from "./MainWrapper";
import { PlanRequiredWrapper } from "./PlanRequiredWrapper";
import { PlanTasksLoader } from "./PlanTasksLoader";
import { RouteContainer } from "./RouteContainer";
import { RiskDriversRoute } from "./Routes/RiskDriversRoute";

import { WeatherCalendarsQsra } from "Components/RiskAnalysisExports/RiskAnalysis";
import CategoryThresholds from "Components/Settings/TaskCategories/CategoryThresholds";
import { IntroTemplate } from "Pages/journeys/intros/IntroTemplate";
import { SimulationView } from "Pages/sites/simulation/SimulationView";
import { TraQsraRoute } from "./Routes/TraQsraRoute";
import { WeatherRoute } from "./Routes/Weather";
import { SimulationRequiredWrapper } from "./SimulationRequiredWrapper";
import { SimulationResult } from "helpers/firebase/simulation";
import { firebaseFunction } from "helpers";



export const SitePage: FC<{
  site: ISite;
  project: IProject;
  plans: IPlan[];
  siteRiskMatrix: IRiskMatrix;
  siteCategories: ITaskCategory[];
}> = ({ site, project, plans, siteRiskMatrix, siteCategories }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
  const [planImportOpen, setPlanImportOpen] = useState(false);
  const [categoriseOpen, setCategoriseOpen] = useState(false);
  const [openingTaskId, setOpeningTaskId] = useState<string | undefined>();
  const [siteStatsModalOpen, setSiteStatsModalOpen] = useState<boolean>(false);
  const [basePlanId, setBasePlanId] = useState<string | undefined>();
  const [printableLink, setPrintableLink] = useState<string | undefined>();
  const [helpLink, setHelpLink] = useState<string | undefined>();


  const SetPlanCategoriesInUse = firebaseFunction("SetPlanCategoriesInUse");
  const closeCategoriseModal = async () => {
    setOpeningTaskId(undefined);
    setCategoriseOpen(false);
    await SetPlanCategoriesInUse({ plan_id: basePlanId });
  };

  const journeys = [
    {
      group: "Tender",
      journeys: [
        {
          title: "Quick Assessment",
          steps: [
            { title: "Intro", path: "/quick-assessment/intro" },
            { title: "Review Thresholds", path: "/settings/thresholds" },
            { title: "Import Plan", path: "/import-plan" },
            { title: "Simulate", path: "/simulate" },
            { title: "Overview", path: "/plan" },
          ],
        },
        {
          title: "Calendar Based TRA",
          steps: [
            { title: "Intro", path: "/calendar-tra/intro" },
            { title: "Review Thresholds", path: "/settings/thresholds" },
            { title: "Import Plan", path: "/import-plan" },
            { title: "Simulate", path: "/simulate" },
            { title: "Identify (optional)", path: "/risk-drivers" },
            { title: "Mitigate (optional)", path: "/mitigations" },
            { title: "Review TRA calendar", path: "/tra-qsra" },
          ],
        },
        {
          title: "Probabilistic QSRA",
          steps: [
            { title: "Intro", path: "/probabilistic-qsra/intro" },
            { title: "Review Thresholds", path: "/settings/thresholds" },
            { title: "Import Plan", path: "/import-plan" },
            { title: "Simulate", path: "/simulate" },
            { title: "Identify (optional)", path: "/risk-drivers" },
            { title: "Mitigate (optional)", path: "/mitigations" },
            { title: "Review TRA calendar", path: "/tra-qsra" },
            {
              title: "Export weather calendars",
              path: "/weather-calendars-qsra",
            },
          ],
        },
      ],
    },
    {
      group: "Construction",
      journeys: [
        {
          title: "Monitor construction",
          steps: [
            { title: "Intro", path: "/monitor-construction/intro" },
            { title: "Weather Forecast", path: "/weather" },
            { title: "Plan Mitigations", path: "/mitigations" },
          ],
        },
        {
          title: "Change event assessment",
          steps: [
            {
              title: "change-event-aseessment",
              path: "/change-event-assessment/intro",
            },
            { title: "Update your Plan", path: "/import-plan" },
            { title: "Simulate", path: "/simulate" },
            { title: "Compare to Baseline", path: "/variance" },
          ],
        },
      ],
    },
  ];

  const [selectedJourney, setSelectedJourney] = useState<string | null>(null);

  const { selectableBasePlans, basePlan, mitigationPlan } = useMemo(() => {
    const selectableBasePlans: IPlan[] = [];
    var basePlan: IPlan | undefined = undefined;
    var mitigationPlan: IPlan | undefined = undefined;

    if (plans) {
      for (const plan of plans) {
        if (!plan.mitigatesPlanId) {
          selectableBasePlans.push(plan);
          if (plan.id == basePlanId) basePlan = plan;
        }
        if (plan.mitigatesPlanId === basePlanId) mitigationPlan = plan;
      }
    }
    return { selectableBasePlans, basePlan, mitigationPlan };
  }, [plans, basePlanId]);

  const [selectedRiskMatrixType, setSelectedRiskMatrixType] =
    useState("project");

  const [selectedVariancePlanId, setSelectedVariancePlanId] = useState<
    string | undefined
  >(mitigationPlan?.id);
  useEffect(() => {
    if (basePlanId && mitigationPlan) {
      setSelectedVariancePlanId(mitigationPlan.id);
    } else {
      setSelectedVariancePlanId(undefined);
    }
  }, [mitigationPlan, basePlanId]);

  useEffect(() => {
    setBasePlanId(site.mainPlanId);
    setSelectedRiskMatrixType(site?.project ? "project" : "user");
  }, [site.id]);

  useEffect(() => {
    if (site.id) {
      dispatch(setActiveSiteId(site.id));
    }
  }, [site.id]);

  const {
    simulation: baseSimulation,
    simulationLoading: baseSimulationLoading,
    requestSimulation: requestBaseSimulation,
  } = useSimulation(site.id, basePlan?.id);

  const { simulationResult } = useSimulationResult(baseSimulation);

    const [mitigationSimulationResults, setMitigationSimulationResults] =
    useState<SimulationResult>();

  const { simulation: mitigationSimulation } = useSimulation(
    site.id,
    mitigationPlan?.id
  );

  const { simulationResult: mitigationSimResults } = useSimulationResult(mitigationSimulation);

  useEffect(() => {
    if (mitigationSimResults) {
      setMitigationSimulationResults(mitigationSimResults);
      console.log(mitigationSimResults);
    } else {
      setMitigationSimulationResults(undefined);
    }
  }, [mitigationSimResults]);

  const { riskMatrix: planRiskMatrix, isLoading: isLoadingBaseRiskMatrix } =
    usePlanRiskMatrix(basePlanId);

  const { minorHashDrift, majorHashDrift } = useCheckPlanHash(
    site.project,
    basePlan?.id
  );

  const tasksUpdating = useAppSelector(selectTasksUpdating);
  const matrixDrift = minorHashDrift || majorHashDrift;
  const [simulationAlert, setSimulationAlert] = useState<string>(
    matrixDrift || tasksUpdating
      ? "Plan is out of sync with project Risk Matrix"
      : ""
  );

  const [simulationInProgress, setSimulationInProgress] =
    useState<boolean>(false);


  const { categories: planCategories, isLoading: isLoadingBaseCategories } =
    useRiskMatrixTopLevelCategories(planRiskMatrix?.id);

  const categories = useMemo(() => {
    if (isLoadingBaseRiskMatrix || isLoadingBaseCategories) return undefined;
    return planRiskMatrix ? planCategories : siteCategories;
  }, [isLoadingBaseRiskMatrix, isLoadingBaseCategories, planRiskMatrix]);

  const { taskDocs } = useTaskDocs(site.id, basePlanId);

  const {
    tasks: allTasksWithSimulationResults,
    isLoading: isLoadingTasks,
    error: tasksError,
    milestones: milestones,
  } = useProcessedTasks(site.id, categories, taskDocs, simulationResult);

  const syncThresholdsSite = (projectId: string, planId: string) => {
    syncThresholds(allTasksWithSimulationResults!, projectId, planId!);
  };
  useTaskTypes(site?.project);
  useOpenSiteEvent(site?.id);

  const renderSelectedJourney = () => {
    if (selectedJourney) {
      for (const group of journeys) {
        for (const journey of group.journeys) {
          if (journey.title === selectedJourney) {
            return (
              <JourneyWrapper
                site={site}
                key={journey.title}
                steps={journey.steps}
                forceStart={true}
              >
                {renderRoutes()}
              </JourneyWrapper>
            );
          }
        }
      }
    }

    return renderRoutes();
  };

  // Update simulation alert and in progress based on simulation state
  useEffect(() => {
    if (baseSimulation) {
      setSimulationInProgress(
        ["pending", "site_pending", "partial", "prepared"].includes(
          baseSimulation?.status || ""
        )
      );
      // Update the simulation alert based on the current state
      setSimulationAlert(
        tasksUpdating || matrixDrift
          ? "Plan is out of sync with project Risk Matrix"
          : ""
      );
    } else {
      setSimulationInProgress(false);
      setSimulationAlert(""); // Clear alert if no simulation
    }
  }, [baseSimulation, baseSimulationLoading, tasksUpdating, matrixDrift]);

  const renderRoutes = () => (
    <Routes>
      <Route
        path="/"
        element={
          <MainWrapper
            site={site}
            selectedPlan={basePlan}
            selectableBasePlans={selectableBasePlans}
            setSelectedPlanId={setBasePlanId}
            syncThresholdsMain={syncThresholdsSite}
            printableLink={printableLink}
            helpLink={helpLink}
            setPlanImportOpen={setPlanImportOpen}
            selectedRiskMatrixType={selectedRiskMatrixType}
            setSelectedRiskMatrixType={setSelectedRiskMatrixType}
          />
        }
      >
        <Route index element={<Navigate to={"plan"} replace />} />
        <Route
          path={"weather"}
          element={WeatherRoute(
            basePlanId,
            basePlan,
            site,
            siteCategories,
            tasksError,
            allTasksWithSimulationResults,
            isLoadingTasks,
            siteRiskMatrix,
            setPrintableLink,
            setHelpLink
          )}
        />
        <Route
          path={"plan"}
          element={
            <RouteContainer>
              <PlanRequiredWrapper
                plan={basePlan}
                site={site}
                renderContent={({ plan: basePlan }) => (
                  <SimulationRequiredWrapper
                    simulation={baseSimulation}
                    simulationLoading={baseSimulationLoading}
                    plan={basePlan}
                    requestSimulation={requestBaseSimulation}
                    setCategoriseOpen={setCategoriseOpen}
                    renderContent={({ simulation }) => (
                      <>
                        <PlanView
                          key={basePlan.id}
                          site={site}
                          selectedPlan={basePlan}
                          simulation={baseSimulation}
                          simulationResult={simulationResult}
                          historicalSourceId={simulation.historicalSourceId}
                          mitigationPlan={mitigationPlan}
                          mitigationSimulationResult={
                            mitigationSimulationResults
                          }
                          setPrintableLink={setPrintableLink}
                          setHelpLink={setHelpLink}
                        />
                      </>
                    )}
                  />
                )}
              />
            </RouteContainer>
          }
        />
        <Route
          path={"simulate"}
          element={
            <SimulationView
              syncThresholds={syncThresholdsSite}
              projectId={project?.id}
              plan={basePlan}
              site={site}
              simulationInProgress={simulationInProgress}
              simulationResult={simulationResult}
            />
          }
        />
        <Route
          path={"tra-qsra"}
          element={TraQsraRoute(
            basePlan,
            site,
            baseSimulation,
            baseSimulationLoading,
            requestBaseSimulation,
            setCategoriseOpen,
            setPrintableLink,
            setHelpLink
          )}
        />
        <Route
          path={"risk-drivers"}
          element={RiskDriversRoute(
            basePlan,
            site,
            baseSimulation,
            baseSimulationLoading,
            requestBaseSimulation,
            setCategoriseOpen,
            siteCategories,
            tasksError,
            allTasksWithSimulationResults,
            isLoadingTasks,
            milestones,
            setPrintableLink,
            setHelpLink,
            setOpeningTaskId
          )}
        />
        <Route
          path={"mitigations"}
          element={
            <RouteContainer>
              <PlanRequiredWrapper
                plan={basePlan}
                site={site}
                renderContent={({ plan: basePlan }) => (
                  <SimulationRequiredWrapper
                    simulation={baseSimulation}
                    simulationLoading={baseSimulationLoading}
                    requestSimulation={requestBaseSimulation}
                    plan={basePlan}
                    setCategoriseOpen={setCategoriseOpen}
                    renderContent={({ simulation: baseSimulation }) => (
                      <PlanTasksLoader
                        site={site}
                        plan={basePlan}
                        simulation={baseSimulation}
                        siteCategories={siteCategories}
                        tasksError={tasksError}
                        allTasksWithSimulationResults={
                          allTasksWithSimulationResults
                        }
                        isLoadingTasks={isLoadingTasks}
                        renderContent={({
                          simulationResult,
                          allTasks: allBaseTasksWithSimulationResults,
                        }) => (
                          <ComparisonPlanLoader
                            key={basePlan?.id}
                            site={site}
                            basePlanId={basePlan.id}
                            comparisonPlan={mitigationPlan}
                            allBaseTasksWithSimulationResults={
                              allBaseTasksWithSimulationResults!
                            }
                            renderContent={({
                              simulation: comparisonSimulation,
                              simulationResult: comparisonSimulationResult,
                              comparisonResultsByBaseTaskId,
                            }) => {
                              return (
                                <MitigationsView
                                  siteId={site.id}
                                  tasks={
                                    allBaseTasksWithSimulationResults || []
                                  }
                                  simulationPlanResult={
                                    simulationResult?.planResults
                                  }
                                  basePlan={basePlan}
                                  mitigationPlan={mitigationPlan}
                                  mitigationSimulation={comparisonSimulation}
                                  mitigationSimulationResult={
                                    comparisonSimulationResult
                                  }
                                  comparisonResultsByBaseTaskId={
                                    comparisonResultsByBaseTaskId
                                  }
                                  setPrintableLink={setPrintableLink}
                                  setHelpLink={setHelpLink}
                                  handleOpenCategorise={(taskId) => {
                                    setOpeningTaskId(taskId);
                                    setCategoriseOpen(true);
                                  }}
                                />
                              );
                            }}
                          />
                        )}
                      />
                    )}
                  />
                )}
              />
            </RouteContainer>
          }
        />
        <Route
          path={"variance"}
          element={
            <RouteContainer>
              <PlanRequiredWrapper
                plan={basePlan}
                site={site}
                renderContent={({ plan: basePlan }) => (
                  <SimulationRequiredWrapper
                    simulation={baseSimulation}
                    simulationLoading={baseSimulationLoading}
                    requestSimulation={requestBaseSimulation}
                    plan={basePlan}
                    setCategoriseOpen={setCategoriseOpen}
                    renderContent={({ simulation: baseSimulation }) => (
                      <PlanTasksLoader
                        site={site}
                        plan={basePlan}
                        simulation={baseSimulation}
                        siteCategories={siteCategories}
                        tasksError={tasksError}
                        allTasksWithSimulationResults={
                          allTasksWithSimulationResults
                        }
                        isLoadingTasks={isLoadingTasks}
                        renderContent={({
                          simulationResult,
                          allTasks: allBaseTasksWithSimulationResults,
                        }) => (
                          <ComparisonPlanLoader
                            key={basePlan?.id + selectedVariancePlanId}
                            site={site}
                            basePlanId={basePlanId!}
                            comparisonPlan={plans.find(
                              ({ id }) => id === selectedVariancePlanId
                            )}
                            allBaseTasksWithSimulationResults={
                              allBaseTasksWithSimulationResults!
                            }
                            renderContent={({
                              simulation: comparisonSimulation,
                              simulationLoading: loadingComparisonSimulation,
                              simulationResult: comparisonSimulationResult,
                              simulationResultLoading:
                                loadingComparisonSimulationResult,
                              requestSimulation: requestComparisonSimulation,
                              comparisonResultsByBaseTaskId,
                              comparisonTaskResultsNotInBase,
                              allTasks,
                            }) => {
                              return (
                                <VarianceView
                                  baseTasks={
                                    allBaseTasksWithSimulationResults || []
                                  }
                                  basePlan={basePlan}
                                  currentSite={site}
                                  baseSimulationPlanResult={
                                    simulationResult?.planResults
                                  }
                                  selectedVariancePlanId={
                                    selectedVariancePlanId
                                  }
                                  setSelectedVariancePlanId={
                                    setSelectedVariancePlanId
                                  }
                                  varianceSimulation={comparisonSimulation}
                                  varianceSimulationResult={
                                    comparisonSimulationResult
                                  }
                                  comparisonResultsByBaseTaskId={
                                    comparisonResultsByBaseTaskId
                                  }
                                  comparisonTaskResultsNotInBase={
                                    comparisonTaskResultsNotInBase
                                  }
                                  requestVarianceSimulation={
                                    requestComparisonSimulation
                                  }
                                  loadingVarianceSimulation={
                                    loadingComparisonSimulation
                                  }
                                  loadingVarianceSimulationResult={
                                    loadingComparisonSimulationResult
                                  }
                                  plans={plans}
                                  setPrintableLink={setPrintableLink}
                                  setHelpLink={setHelpLink}
                                  allTasks={allTasks!}
                                />
                              );
                            }}
                          />
                        )}
                      />
                    )}
                  />
                )}
              />
            </RouteContainer>
          }
        />
        <Route
          path={"settings/thresholds"}
          element={
            <CategoryThresholds
              site={site}
              selectedRiskMatrixType={selectedRiskMatrixType}
              plan={basePlan}
            />
          }
        />
        <Route
          path={"settings/compensation-events"}
          element={<CompensationEvents site={site} />}
        />
      </Route>
      <Route
        path="quick-assessment/intro"
        element={
          <IntroTemplate
            title="Quick Assessment"
            description="The Quick Assessment workflow consists of three streamlined steps that enable you to evaluate the weather risk in your current project plan. This process delivers a quick but clear overview of potential weather impacts."
            items={[
              "A printable overview detailing weather risk across various confidence intervals.",
              "A risk heatmap highlighting the primary areas of concern.",
              "Identification of key weather factors driving these risks.",
              "Access to the source data and methodology underpinning the assessment.",
            ]}
            benefits={[
              "Achieve a swift yet precise understanding of weather risks in your current plan.",
              "Facilitate informed decision-making and high-risk management standards.",
            ]}
          />
        }
      />

      <Route
        path="calendar-tra/intro"
        element={
          <IntroTemplate
            title="Calendar Based TRA"
            description="The Calendar Base TRA workflow consists of five streamlined steps that enable you to evaluate the weather risk in your current project plan. This process helps you to identify weather risk, potentially mitigate it and then incorporate these results back into your schedule software."
            items={[
              "Weather calendars compatible with your preferred scheduling software.",
              "A simple way to integrate risk into your schedule.",
            ]}
            benefits={[
              "Super accurate way of incorporating risk into your schedule.",
            ]}
          />
        }
      />

      <Route
        path="probabilistic-qsra/intro"
        element={
          <IntroTemplate
            title="Probabilistic QSRA"
            description="The QSRA Calendars workflow consists of five streamlined steps that enable you to evaluate the weather risk in your current project plan. This process helps you to identify weather risk, potentially mitigate it and then incorporate these results back into your risk software to complete a state of the art risk analysis."
            items={[
              "Weather calendars compatible with your preferred risk software.",
              "A simple way to integrate weather down time into your risk analysis.",
            ]}
            benefits={[
              "Best in class weather risk assessment for your project risk.",
            ]}
          />
        }
      />

      <Route
        path="monitor-construction/intro"
        element={
          <IntroTemplate
            title="Monitor Construction"
            description="The Monitor Construction workflow consists of three streamlined steps that enable you to evaluate the weather risk in your current project plan. This process helps you to identify weather risk, potentially mitigate it and then incorporate these results back into your risk software to complete a state of the art risk analysis."
            items={[
              "Weather calendars compatible with your preferred risk software.",
              "A simple way to integrate weather down time into your risk analysis.",
            ]}
            benefits={[
              "Best in class weather risk assessment for your project risk.",
            ]}
          />
        }
      />

      <Route
        path="change-event-assessment/intro"
        element={
          <IntroTemplate
            title="Change Event Assessment"
            description="The Change Event Assessment workflow consists of three streamlined steps that enable you to compare weather risk in your current plan against a chosen baseline. This process helps you to identify the impact of change on your weather risk pot, to take focused action to alleviate this impact and to generate a report which can help you to justify additional time risk."
            items={[
              "Report highlighting key difference in weather risk",
              "Clear list of main activities that have changed and are driving change in risk",
            ]}
            benefits={[
              "Justify better/accurate time risk",
              "Stay on top of change events",
            ]}
          />
        }
      />

      <Route
        path={"import-plan"}
        element={
          <PlanImport
            handleSuccess={(planId: string) => {
              setPlanImportOpen(false);
              setBasePlanId(planId);
              if (!site?.mainPlanId) {
                dispatch(setAsMainPlan(site, planId));
              }
                  // Sets which activities are in use for this plan
              const SetPlanCategoriesInUse = firebaseFunction("SetPlanCategoriesInUse");
              SetPlanCategoriesInUse({ plan_id: basePlan?.id }).then(() => {
                navigate("./simulate");
              });
            }}
            site={site}
            project={project}
          />
        }
      />

      <Route
        path="weather-calendars-qsra"
        element={
          basePlan?.id && site?.orgId ? (
            <WeatherCalendarsQsra
              orgId={site.orgId}
              siteId={site.id}
              planId={basePlan.id}
            />
          ) : null
        }
      />
    </Routes>
  );

  return (
    <>
      <div className="flex h-full relative pr-10 pl-4 pt-2">
        <aside className="absolute h-full md:sticky top-0 z-40">
          <Sidebar setIsOpened={setSidebarIsOpen} isOpened={sidebarIsOpen}>
            <SidebarContentJourneys
              journeys={journeys}
              onJourneySelect={setSelectedJourney}
              selectedJourney={selectedJourney}
            />
            <div className="pt-2" />
            <SidebarContent
              site={site}
              selectedJourney={selectedJourney}
              setSelectedJourney={setSelectedJourney}
              simulationAlert={simulationAlert}
              simulationInProgress={simulationInProgress}
              handleOpenCategorise={() => setCategoriseOpen(true)}
            />
          </Sidebar>
        </aside>
        {renderSelectedJourney()}
        <div
          className={`bg-gray-600 bg-opacity-50 z-40 h-full right-5 left-0 ${
            sidebarIsOpen ? "hidden sm:block fixed" : "hidden"
          } md:hidden`}
        />
        {categoriseOpen && (
          <Modal
            id="categorise"
            handleCloseModal={closeCategoriseModal}
            xl={true}
            title="Categorise Tasks"
            subTitle={site?.name}
          >
            <Categorise
              site={site}
              plan={basePlan}
              handleComplete={closeCategoriseModal}
              initialOpenTaskId={openingTaskId}
            />
          </Modal>
        )}
        {siteStatsModalOpen && (
          <Modal
            id="site-stats-modal"
            xl={true}
            title="Site Stats Download"
            subTitle={site.name}
          >
            <Modal
              id="site-stats-report"
              handleCloseModal={() => setSiteStatsModalOpen(false)}
            >
              <SiteWeatherDownloadSlide
                handleCloseModal={() => setSiteStatsModalOpen(false)}
                site={site}
                projectId={site.project}
              />
            </Modal>
          </Modal>
        )}
      </div>
    </>
  );
};
